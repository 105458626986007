import styled from "styled-components";
import { SectionHeading } from "../CommonStyles";

export const Wrapper = styled.div`
  width: calc(100% - 48px);
  border-radius: 20px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: 16px;
  gap: 20px;
  text-align: center;

  a {
    width: 100%;
    max-width: 200px;
    height: 45px;
    text-decoration: none;
    border-radius: 35px;
    font-size: 18px;
    font-weight: bold;
    background-color: black;
    color: white;
    font-family: "Poppins", sans-serif;
    display: grid;
    place-items: center;
    margin-inline: auto;
  }
`;

export const CTATitle = styled(SectionHeading)`
  line-height: 1.4;
  margin: 0;
  color: black;
`;
