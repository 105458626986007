export const galleryImages = [
  {
    src: "/assets/Camera Roll/MEDIA/3d.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/atl-1.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/course-coding.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/p2.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-1.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-2.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-3.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-5.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-7.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-10.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-12.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-13.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-14.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-16.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-20.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-21.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-25.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-26.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-36.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-41.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-44.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-45.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-46.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-47.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-48.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-49.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-50.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-51.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-52.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-53.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-54.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-55.webp",
  },

  {
    src: "/assets/Camera Roll/MEDIA/web-56.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-57.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-58.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-59.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-60.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-61.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-62.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-63.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-64.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-65.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-66.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-68.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-69.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-70.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-71.webp",
  },
  {
    src: "/assets/Camera Roll/MEDIA/web-72.webp",
  },
];
