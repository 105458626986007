export const workshops = [
  {
    img: "/assets/Camera Roll/WORKSHOPS AND CAMPS/web-38.webp",
    title: "3 Days STREAM Workshop",
    desc: [
      "Topics Covered Coding, AI, Robotics",
      "2 hours teaching session and 1 hour Doubt clearing session per Day",
      "Workshop will be conducted by Klassway",
      "Certificate of Participation",
    ],
  },
  {
    img: "/assets/Camera Roll/WORKSHOPS AND CAMPS/web-39.webp",
    title: "5 Days STREAM Workshop",
    desc: [
      "Topics Covered Coding, AI, Robotics & IoT",
      "2 hours teaching session and 1 hour Doubt clearing session per Day",
      "Workshop will be conducted by Klassway",
      "Certificate of Participation",
    ],
  },
];
