export const atalImages = [
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 1.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 2.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 3.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 4.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 5.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 6.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 7.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 8.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 9.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 10.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 11.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 12.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 13.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 14.webp",
  },
  {
    src: "/assets/Camera Roll/ATAL TINKERING LAB/ATL IMAGES/ATL 15.webp",
  },
];
