import React from "react";
import './loader.css'
const Loader = ()=>{
    return(
        <>
            <div className="loader"></div>
        </>
    )
} 

export default Loader