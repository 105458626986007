export const coursesData = [
  {
    src: "/assets/Camera Roll/COURSE/course-ai.webp",
    title: "ARTIFICIAL INTELLIGENCE (AI)",
    content:
      "Through the exploration of Artificial Intelligence from its core pillars to coding and implementing Machine Learning programs and models, students will acquire a deeper understanding of the concept. The course covers the technological advancements that enabled AI to become a reality, then moves on to exploring what AI is in depth, educating students on what differentiates this concept from the conventional programming that we are very familiar with today.",
  },
  {
    src: "/assets/Camera Roll/COURSE/course-coding.webp",
    title: "CODING",
    content:
      "In this course, students learn the basics of coding. In addition, students will be introduced to loops, logic, variables, and radio communications, as well as how to write codes to create games, functions, and unlimited projects using these concepts!",
  },
  {
    src: "/assets/Camera Roll/COURSE/course-robotics.webp",
    title: "ROBOTICS",
    content:
      "Students learn basics of science and robotics by this course. It teaches students to design, program and control fully functional models by asking questions, solving problems, modeling, prototyping, investigating, analyzing and interpreting data.",
  },
  {
    src: "/assets/Camera Roll/COURSE/course-electronics.webp",
    title: "ELECTRONICS ",
    content:
      "Students can learn about electrical circuits and electronic devices by building a variety of digital systems.",
  },
  {
    src: "/assets/Camera Roll/COURSE/course-3d.webp",
    title: "3D MODELING & PRINTING",
    content:
      "During this course, students will be introduced to 3D printing and 3D modeling concepts. They will integrate different engineering concepts to bring their ideas to life and gain the skills to work like design engineers. In addition to reverse engineering real life models and designing their own prototypes, students will also work on large group projects.",
  },
  {
    src: "/assets/Camera Roll/COURSE/course-app-development.webp",
    title: " APP DEVELOPMENT",
    content:
      "Students will learn how to create Android apps. It uses drag-and-drop programming blocks to develop apps. Students will learn how to use basic technology daily. Apps can be created by students and downloaded onto their mobile devices.",
  },
  {
    src: "/assets/Camera Roll/COURSE/course-physical-computing.webp",
    title: "PHYSICAL COMPUTING",
    content:
      "This course combines the knowledge of programming and electronics in order to build digital systems and control those using Arduino micro-controllers.",
  },
  {
    src: "/assets/Camera Roll/COURSE/course-iot.webp",
    title: "INTERNET OF THINGS",
    content:
      "During this course, students learn how to program ESP & Pi family (credit card-sized computers) in Python. As well as building an IOT activity using Raspberry Pi, GOPIGO circuits, motors, sensors, and the programming skills they’ve learned. Students integrate science and math concepts into their activities.",
  },
  {
    src: "/assets/Camera Roll/COURSE/course-drone.webp",
    title: "AERO MODELLING & DRONES",
    content:
      "Students learn about aerodynamics and Bernoulli's principle, assemble and program their drones to fly autonomously, and accomplish certain objectives on a fly circuit at our center.",
  },
];
