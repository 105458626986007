import styled from "styled-components";

export const Wrapper = styled.div`
  width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-inline: 20px;
`;

export const AverageRating = styled.h1``;
