import React from "react";
import { Container } from "../CommonStyles";
import {
  AnimatedTextWrap,
  ProsDesc,
  ProsDetailSection,
  ProsImage,
  ProsImageSection,
  ProsSection,
  ProsTitle,
  ProsWrap,
  Wrapper,
} from "./Pros.styles";
import { pros } from "./prosData";
import AnimatedText from "react-animated-text-content";
const Pros = () => {
  return (
    <Container>
      <Wrapper>
        <AnimatedTextWrap>
          <AnimatedText
            type="chars"
            animationType="float"
            interval={0.1}
            duration={1.7}
            tag="h1"
          >
            Ignite Your Love for Learning with STEAM AI LAB!
          </AnimatedText>
        </AnimatedTextWrap>
        <ProsSection>
          {pros.map((data, index) => (
            <ProsWrap key={index}>
              <ProsImageSection>
                <ProsImage src={data.img} />
              </ProsImageSection>
              <ProsTitle>{data.title}</ProsTitle>
              <ProsDetailSection>
                <ProsTitle>{data.title}</ProsTitle>
                <ProsDesc>{data.desc}</ProsDesc>
              </ProsDetailSection>
            </ProsWrap>
          ))}
        </ProsSection>
      </Wrapper>
    </Container>
  );
};

export default Pros;
