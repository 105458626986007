export const aboutData = [
  {
    img: "./assets/Camera Roll/ABOUT/Our-Approach.webp",
    title: "Our Approach",
    info: "With more than 7+ years of experience as a STREAM Education (Science, Technology, Robotics, Engineering, Arts, Mathematics) solution provider, we have honed our methods to develop students into future-ready leaders",
  },
  {
    img: "./assets/Camera Roll/ABOUT/Our-MISSION.webp",
    title: "Our Mission",
    info: "Our mission is to transform students into creators and innovators. One of our long-term goals is to help kids learn to think creatively, work collaboratively and reason systematically – from the youngest age.",
  },
  {
    img: "./assets/Camera Roll/ABOUT/OUR-VISION.webp",
    title: "Our Vision",
    info: "Our vision is to create a technology ecosystem that provides people with access to technology, resources, trainings and encourages them to solve real-life challenges through innovation.",
  },
];
